import * as React from "react";
import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="May 2024" />
		<NewsHeader />
		<h2>May 2024</h2>

		<h3 className="blogdate">
			Tuesday, May 20, 2024
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Join me this week or next</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				If you're in Brasilia, join me <span className="bold">for free</span> at the National Library of Brasilia on May 27! See my <Link to="/events/">events</Link> page.
			</p>
			<p>
				Lately I've been livestreaming and I've been getting a lot out of it;
				I get to work on my repertoire as well as analyze aspects of my performance.
				These are public performances... you're welcome to join me <span className="bold">for free</span>!
				I've developed a nice camera setup to make it warm and special.
				Each livestream has a five-minute, previously recorded segment showing some aspect of life in and around home or Brasília;
				I also tell some stories about a CD from my collection.
				You can find the next next scheduled livestreams
				at <a href="//www.youtube.com/@jesserivest/live">Youtube</a> and <a href="//ursalive.com/jesserivest">Ursa Live</a> (they are independent, not simulcast).
			</p>
			<p>
				In June I'll probably take a break from the livestreaming, but I do aim to return to it perhaps in July.
				See below for a song captured from a previous livestream;
				also there are edited-down teaser videos from some of the past livestreams over at <a href="//www.youtube.com/@jesserivest/live">Youtube</a>.
			</p>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="https://www.youtube-nocookie.com/embed/VO5_b0R0N6s"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<div className="main--centerwrapper p">
			</div>

			<div className="main--centerwrapper p">
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
